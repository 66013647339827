import React from 'react';
import { connectHits } from 'react-instantsearch-dom';

import SearchItem from './SearchItem';
import './SearchResults.scss';
import { ISearchResults } from './models';

const MAX_VIEWED_RESULT_LENGTH = 3;

export const SearchResults = ({ hits }: ISearchResults) => (
  <div className="dt-algolia-search__results">
    <ul className="dt-algolia-search__results-list">
      {hits.slice(0, MAX_VIEWED_RESULT_LENGTH).map((hit) => (
        <SearchItem key={hit.objectId} hit={hit} attribute="query" />
      ))}
    </ul>
  </div>
);

export default connectHits(SearchResults);
