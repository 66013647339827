import React, { FC } from 'react';

import IconCustom from 'components/common/IconCustom';
import { navigateToSearchPage } from 'components/Header/HeaderSearch/AlgoliaSearchBox/utils';

interface ILastSearchItem {
  text: string;
  closeMenu: () => void;
}

const LastSearchItem: FC<ILastSearchItem> = ({ text, closeMenu }) => {
  const handleLastSearchItemClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    closeMenu();
    navigateToSearchPage(event, text);
  };

  return (
    <li>
      <button
        onClick={handleLastSearchItemClick}
        className="dt-algolia-search__last-results-list-btn"
        type="button"
      >
        <IconCustom icon="search_icon" />
        {text}
      </button>
    </li>
  );
};

export default LastSearchItem;
