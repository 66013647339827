import React, { useEffect, useRef } from 'react';
import { connectAutoComplete } from 'react-instantsearch-dom';
import Button from 'react-bootstrap/Button';

import IconCustom from 'components/common/IconCustom';

import SearchResults from '../SearchResults';
import { getUserSearches, navigateToSearchPage } from '../utils';
import LastSearchItem from '../LastSearchItem';
import { ISearchBox } from './model';

const MIN_SEARCH_QUERY_LENGTH = 3;

const SearchBox = ({ currentRefinement, refine, closeMenu, customFields }: ISearchBox) => {
  const lastUserSearches = getUserSearches();

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    refine(event.currentTarget.value);
  };

  const handleSearchFormSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    closeMenu();
    navigateToSearchPage(event, currentRefinement);
  };

  const inputText = useRef(null);

  useEffect(() => {
    inputText?.current?.focus();
  }, []);

  return (
    <form className="dt-algolia-search" noValidate role="search" onSubmit={handleSearchFormSubmit}>
      <div className="dt-algolia-search__row">
        <IconCustom icon="search_icon" />

        <input
          ref={inputText}
          className="dt-algolia-search__input"
          type="search"
          placeholder={customFields.placeholderSearch || 'What are you looking for?'}
          aria-label={customFields.placeholderSearch || 'What are you looking for?'}
          value={currentRefinement}
          onChange={handleSearchInputChange}
        />

        <Button
          variant="outline-light"
          className="dt-algolia-search__results-btn d-none d-md-inline-flex"
          type="submit"
        >
          {customFields.viewAllResults || 'View all results'}
          <IconCustom icon="long_arrow_icon" />
        </Button>
      </div>
      {currentRefinement.length < MIN_SEARCH_QUERY_LENGTH && !!lastUserSearches.length && (
        <div className="dt-algolia-search__last-results">
          <p className="dt-algolia-search__last-results-text">
            {customFields.previousSearches || 'Previous searches'}
          </p>
          <ul className="dt-algolia-search__last-results-list">
            {lastUserSearches.map((lastSearchItem) => (
              <LastSearchItem key={lastSearchItem} text={lastSearchItem} closeMenu={closeMenu} />
            ))}
          </ul>
        </div>
      )}
      {currentRefinement.length >= MIN_SEARCH_QUERY_LENGTH && (
        <SearchResults currentRefinement={currentRefinement} />
      )}
      <Button
        variant="outline-light"
        className="dt-algolia-search__results-btn d-md-none"
        onClick={handleSearchFormSubmit}
      >
        {customFields.viewAllResults || 'View all results'}
        <IconCustom icon="long_arrow_icon" />
      </Button>
    </form>
  );
};

export default connectAutoComplete(SearchBox);
