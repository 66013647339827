import React from 'react';
import { navigate } from 'gatsby';

import { isBrowser, getLocationLang } from 'utils/browser';
import { getStorageData, setStorageData } from 'utils/storage';
import { LocalStorageData, LastSearches } from './models';

const LOCAL_STORAGE_LAST_SEARCHES_KEY = 'LAST_SEARCHES';
const LAST_SEARCHES_QUANTITY = 3;

const readUserSearches = (): LocalStorageData =>
  isBrowser() ? getStorageData(LOCAL_STORAGE_LAST_SEARCHES_KEY) : null;

const restoreUserSearches = (localStorageData: LocalStorageData): LastSearches =>
  localStorageData === null ? [] : (localStorageData as string[]);

const writeUserSearches = (lastUserSearches: LastSearches): void =>
  setStorageData(LOCAL_STORAGE_LAST_SEARCHES_KEY, lastUserSearches);

const isAlreadyAdded = (lastUserSearches: LastSearches, seacrhRequest: string): number => {
  return lastUserSearches.indexOf(seacrhRequest);
};

const formatUserSearchWithExistingRequest = (
  searchRequest: string,
  lastUserSearches: LastSearches,
  foundIndex: number
): LastSearches => {
  return [
    searchRequest,
    ...lastUserSearches.slice(0, foundIndex),
    ...lastUserSearches.slice(foundIndex + 1),
  ];
};

export const getUserSearches = (): LastSearches => restoreUserSearches(readUserSearches());

export const saveUserSearch = (searchRequest: string): void => {
  const lastUserSearches = getUserSearches();
  if (searchRequest.length) {
    if (!lastUserSearches.length) {
      return writeUserSearches([searchRequest]);
    }

    if (lastUserSearches.length < LAST_SEARCHES_QUANTITY) {
      const foundIndex = isAlreadyAdded(lastUserSearches, searchRequest);

      return writeUserSearches(
        foundIndex !== -1
          ? formatUserSearchWithExistingRequest(searchRequest, lastUserSearches, foundIndex)
          : [searchRequest, ...lastUserSearches]
      );
    }

    if (lastUserSearches.length === LAST_SEARCHES_QUANTITY) {
      const foundIndex = isAlreadyAdded(lastUserSearches, searchRequest);

      return writeUserSearches(
        foundIndex !== -1
          ? formatUserSearchWithExistingRequest(searchRequest, lastUserSearches, foundIndex)
          : [searchRequest, ...lastUserSearches.slice(0, LAST_SEARCHES_QUANTITY - 1)]
      );
    }
  }
};

export const navigateToSearchPage = (
  event: React.SyntheticEvent<HTMLElement>,
  searchQuery: string
): void => {
  const pathLang = getLocationLang();
  const langPrefix = pathLang ? `/${pathLang}` : '';
  const searchPath = `/search-results/?query=${searchQuery}`;
  const navigatePath = langPrefix.concat(searchPath);

  event.preventDefault();
  saveUserSearch(searchQuery);
  navigate(navigatePath);
};
