import React from 'react';
import { connectHighlight } from 'react-instantsearch-dom';
import { navigateToSearchPage } from '../../utils';

const SearchItem = ({ highlight, attribute, hit }) => {
  const parsedHit = highlight({
    highlightProperty: '_highlightResult',
    attribute,
    hit,
  });

  const handleSearchItemClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    navigateToSearchPage(event, hit.query || 'searchURLMock');
  };

  return (
    <li>
      <button
        className="dt-algolia-search__results-list-btn"
        onClick={handleSearchItemClick}
        type="button"
      >
        {parsedHit.map((part) =>
          part.isHighlighted ? (
            <mark key={part.value}>{part.value}</mark>
          ) : (
            <span key={part.value}>{part.value}</span>
          )
        )}
      </button>
    </li>
  );
};

export default connectHighlight(SearchItem);
