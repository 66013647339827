import React, { FC } from 'react';
import { InstantSearch } from 'react-instantsearch-dom';
import algoliasearch from 'algoliasearch/lite';

import './AlgoliaSearchBox.scss';
import CustomAlgoliaSearchBox from 'components/Header/HeaderSearch/AlgoliaSearchBox/SearchBox';
import { IAlgoliaSearchBox } from './models';

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID as string,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY as string
) as any;

const indexName = process.env.GATSBY_ALGOLIA_SUGGESTIONS_INDEX as string;

const AlgoliaSearchBox: FC<IAlgoliaSearchBox> = ({ closeMenu, customFields }) => {
  return (
    <InstantSearch searchClient={searchClient} indexName={indexName}>
      <CustomAlgoliaSearchBox closeMenu={closeMenu} customFields={customFields} />
    </InstantSearch>
  );
};

export default AlgoliaSearchBox;
